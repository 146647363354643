import axios from 'axios'
import appConfig from 'configs/app.config'
import { TOKEN_TYPE, REQUEST_HEADER_AUTH_KEY } from 'constants/api.constant'
import { PERSIST_STORE_NAME } from 'constants/app.constant'
import deepParseJson from 'utils/deepParseJson'
import store from '../store';
import {
    onSignOutSuccess,
    // setRefreshToken,
    //  setToken 
} from '../store/auth/sessionSlice'
// const refreshToken = store.getState().auth.session.refreshToken;
const unauthorizedCode = [401]

const BaseService = axios.create({
    timeout: 60000,
    baseURL: appConfig.apiPrefix,
})

BaseService.interceptors.request.use(
    (config) => {
        const rawPersistData = localStorage.getItem(PERSIST_STORE_NAME)
        const persistData = deepParseJson(rawPersistData)

        let accessToken = persistData.auth.session.token

        if (!accessToken) {
            const { auth } = store.getState()
            accessToken = auth.session.token
        }

        if (accessToken) {
            config.headers[
                REQUEST_HEADER_AUTH_KEY
            ] = `${TOKEN_TYPE}${accessToken}`
        }

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

BaseService.interceptors.response.use(
    (response) => response,
    // async function (error) {
    //     const originalRequest = error.config;
    //     let refreshTokenError, res
    //     if (error.response.status === 401 && !originalRequest._retry) {
    //         originalRequest._retry = true;
    //         const refreshToken = store.getState().auth.session.refreshToken;
    //         [refreshTokenError, res] = await axios({
    //             method: "POST",
    //             url: `${process.env.REACT_APP_API_URL}/v1/auth/refresh`,
    //             // withCredentials: true,
    //             headers: { "Content-Type": "application/json" },
    //             data: { refreshToken: refreshToken }
    //         })
    //             .then(async (response) => {
    //                 //handle success
    //                 // const userID = parseJwt(response.data.access).userId
    //                 // SetLoginCookie(userID, true)
    //                 console.log("refresh token api response", response)
    //                 const { accessToken, refreshToken } = response.data.data;
    //                 setToken(accessToken)
    //                 setRefreshToken(refreshToken);
    //                 return [null, await axios.request(originalRequest)];
    //             }).catch(function (error) {

    //                 // RemoveLoginCookie('userID', 'isLoggedin')
    //                 // setRedirect(true)
    //                 console.log(error.response);
    //                 if (error.response) {
    //                     // Request made and server responded
    //                 } else if (error.request) {
    //                     // The request was made but no response was received
    //                     console.log(error.request);
    //                 } else {
    //                     // Something happened in setting up the request that triggered an Error, 404 not found
    //                     console.log('Error', error.message);
    //                 }
    //             });
    //         if (refreshTokenError) {
    //             return Promise.reject(refreshTokenError);
    //         }
    //         return Promise.resolve(res)
    //     }
    //     return Promise.reject(error)

    // }
    (error) => {
        console.log("error.config", error.config);
        const { response } = error

        if (response && unauthorizedCode.includes(response.status)) {
            store.dispatch(onSignOutSuccess())
        }


        return Promise.reject(error)
    }
)

export default BaseService
