import { createSlice } from '@reduxjs/toolkit'

export const sessionSlice = createSlice({
    name: 'auth/session',
    initialState: {
        token: '',
        refreshToken: '',
        signedIn: false,
    },
    reducers: {
        onSignInSuccess: (state, action) => {
            console.log("onSignin Success", action.payload);
            state.signedIn = true
            state.token = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
        },
        onSignOutSuccess: (state) => {
            state.signedIn = false
            state.token = ''
            state.refreshToken = ''
        },
        setToken: (state, action) => {
            state.token = action.payload
        },
        setRefreshToken: (state, action) => {
            state.refreshToken = action.payload
        },
    },
})

export const { onSignInSuccess, onSignOutSuccess, setToken, setRefreshToken } =
    sessionSlice.actions

export default sessionSlice.reducer
