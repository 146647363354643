import ApiService from './ApiService'
import store from 'store'
export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/v1/auth/login`,
        method: 'post',
        data,
    })
}
// export async function apiSignUp(data) {
//     return ApiService.fetchData({
//         url: '/sign-up',
//         method: 'post',
//         data,
//     })
// }
export async function apiSignUp(data) {
    console.log("data from send request", data);
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/v1/users/company-owner`,
        method: 'post',
        data,
    })
}
export async function brokerApiSignUp(data) {
    console.log("data from send request", data);
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/v1/users/broker`,
        method: 'post',
        data,
    })
}
export async function OptVerification(data) {
    console.log("data from send request", data);
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/v1/auth/verify-otp`,
        method: 'post',
        data,
    })
}
export async function resendOtpApi(data) {
    console.log("data from send request", data);
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/v1/auth/resend-otp`,
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    const token = store.getState().auth.session.token;
    return ApiService.fetchData({
        url: `${process.env.REACT_APP_API_URL}/v1/auth/logout`,
        method: 'post',
        headers: {
            "x-access-token": token,
        },
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}
