import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { OptVerification, apiSignIn, apiSignOut, apiSignUp, brokerApiSignUp, resendOtpApi } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            console.log("sign in response", resp);
            if (resp.data) {
                const { accessToken, refreshToken, first_name, last_name, email, user_type_id } = resp.data.data;
                dispatch(onSignInSuccess({ accessToken, refreshToken }));
                dispatch(
                    setUser(
                        {
                            avatar: '',
                            userName: `${first_name} ${last_name}`,
                            authority: user_type_id === 1 ? ['admin'] : user_type_id === 4 ? ['broker'] : user_type_id === 3 ? ['driver'] : ['user'],
                            email: email,
                            token: accessToken,
                            refreshToken: refreshToken
                        }
                    )
                );
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return resp;
            }
        } catch (errors) {
            console.log("signup errors", errors)
            return errors.response.data
        }
    }
    const vrifyOtp = async (values, userData) => {
        try {
            const resp = await OptVerification(values)
            const { accessToken, refreshToken } = resp.data.data;
            console.log("response", resp);
            console.log("accessToken", resp.data.data.accessToken);
            if (resp.data) {
                dispatch(onSignInSuccess(resp.data.data.accessToken));
                dispatch(
                    setUser(
                        {
                            avatar: '',
                            userName: userData.name,
                            authority: userData.user_type_id === 1 ? ['admin'] : userData.user_type_id === 4 ? ['broker'] : userData.user_type_id === 3 ? ['driver'] : ['user'],
                            email: userData.email,
                            token: accessToken,
                            refreshToken: refreshToken
                        }
                    )
                )
            }
            const redirectUrl = query.get(REDIRECT_URL_KEY)
            navigate(
                redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
            )
            return resp;
        } catch (errors) {
            console.log("signup errors", errors)
            return errors.response.data
        }
    }
    const resendOTPRquest = async (values) => {
        try {
            const resp = await resendOtpApi(values)
            console.log("response", resp);
            return resp;
        } catch (errors) {
            console.log("resendOTPRquest errors", errors)
            return errors.response.data
        }
    }

    const signUp = async (values) => {
        console.log("data value signup function", values);
        try {
            const resp = await apiSignUp(values)
            console.log("response", resp);
            return resp;
            // if (resp.data) {
            //     const { token } = resp.data
            //     dispatch(onSignInSuccess(token))
            //     if (resp.data.user) {
            //         dispatch(
            //             setUser(
            //                 resp.data.user || {
            //                     avatar: '',
            //                     userName: 'Anonymous',
            //                     authority: ['USER'],
            //                     email: '',
            //                 }
            //             )
            //         )
            //     }
            //     const redirectUrl = query.get(REDIRECT_URL_KEY)
            //     navigate(
            //         redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
            //     )
            //     return {
            //         status: 'success',
            //         message: '',
            //     }
            // }
        } catch (errors) {
            console.log("signup errors", errors)
            return errors.response.data
        }
    }
    const brokerSignUp = async (values) => {
        console.log("data value signup function", values);
        try {
            const resp = await brokerApiSignUp(values)
            console.log("response", resp);
            return resp;
        } catch (errors) {
            console.log("signup errors", errors)
            return errors.response.data
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        try {
            const resp = await apiSignOut();
            if (resp.data) {
                handleSignOut();
                return resp;
            }
        } catch (errors) {
            console.log("signup errors", errors)
            return errors.response.data
        }
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        brokerSignUp,
        signOut,
        vrifyOtp,
        resendOTPRquest
    }
}

export default useAuth
